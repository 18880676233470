/* eslint-disable no-undef */

import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { store } from '../redux/store';
import { removeAuth, setAuth, updateAuth } from '../redux/auth.slice';
import { removeProfile } from '../redux/profile.slice';
import { apiDisconnectWallet } from './wallet';

export const apiAnonymousLogin = async () => {
  try {
    const response = await axios.post('/api/client/v2/user/login');
    const token = response.data.data;

    store.dispatch(
      setAuth({
        isLoggedIn: true,
        isAnonymousLogin: true,
        accessToken: token,
      }),
    );
    return true;
  } catch {
    enqueueSnackbar('Failed to get guest access from the server', {
      variant: 'error',
    });
    return false;
  }
};

export const apiLogin = async ({ wallet_address, chain }) => {
  return new Promise((resolve) => {
    grecaptcha.ready(async () => {
      try {
        const token = await grecaptcha.execute(
          process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY,
          { action: 'login' },
        );
        const response = await axios.put('/api/client/v1/user/token', {
          wallet_address,
          chain,
          recaptcha_token: token,
        });
        if (response.data.data) {
          store.dispatch(
            setAuth({
              isLoggedIn: true,
              isAnonymousLogin: false,
              isVerified: true,
              accessToken: response.data.data,
            }),
          );
          return resolve('verified');
        }
        return resolve('verified');
      } catch (err) {
        if (err.response?.data?.join_telegram_link) {
          return resolve(err.response.data.join_telegram_link);
        }
        if (process.env.REACT_APP_ENV === 'development') {
          enqueueSnackbar({
            message: JSON.stringify(err.response?.data),
            variant: 'info',
          });
        }
        return resolve(false);
      }
    });
  });
};

export const apiVerify = async ({ code }) => {
  return new Promise((resolve) => {
    grecaptcha.ready(async () => {
      try {
        const token = await grecaptcha.execute(
          process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY,
          { action: 'verify' },
        );
        const response = await axios.post('/api/client/v1/user/verify', {
          recaptcha_token: token,
          promo_code: code,
        });
        if (response.data.data) {
          store.dispatch(
            updateAuth({
              accessToken: response.data.data,
            }),
          );
          return resolve(true);
        }
        return resolve(false);
      } catch {
        return resolve(false);
      }
    });
  });
};

export const apiLogout = async () => {
  store.dispatch(removeAuth());
  store.dispatch(removeProfile());
  await apiDisconnectWallet();
};

export const apiTwitterCallback = async () => {
  try {
    await axios.get('/api/client/v2/user/auth/x/callback');
    return true;
  } catch {
    return false;
  }
};
