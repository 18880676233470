import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  ButtonBase,
  Collapse,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { Scaffold } from '../../components/layouts/Scaffold';
import { NavLink, useNavigate } from 'react-router-dom';
import { ArrowLeftIcon, PlusIcon } from 'lucide-react';

import imgGuide from '../../assets/guides/journey-guide.png';

const LevelGuide = ({ title, subtitle, summary, details, open, onToggle }) => {
  return (
    <Box>
      <Typography
        fontSize={32}
        fontWeight={500}
        lineHeight={1.25}
        color="beauBlue"
      >
        {title}
      </Typography>
      <Stack mt={1.5} direction="row" alignItems="center">
        <Typography
          fontSize={26}
          fontWeight={500}
          lineHeight={1.5}
          color="isabelline"
          flex={1}
        >
          {subtitle}
        </Typography>
        <ButtonBase
          sx={{
            width: 32,
            height: 32,
            borderRadius: 32,
            bgcolor: open ? 'icterine' : 'greyishBlue',
            transform: open ? 'rotateZ(45deg)' : 'rotateZ(0)',
            transition: 'all 0.2s',
          }}
          onClick={onToggle}
        >
          <PlusIcon />
        </ButtonBase>
      </Stack>
      <Typography mt={2} fontSize={14} lineHeight={1.75} color="isabelline">
        {summary}
      </Typography>
      <Collapse in={open} orientation="vertical">
        <Box pt={2}>{details}</Box>
      </Collapse>
    </Box>
  );
};

const Divider = () => {
  return (
    <Box
      sx={{
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: '#585858',
        my: 4,
      }}
    />
  );
};

export const AboutJourneyPage = () => {
  const navigate = useNavigate();

  const [values, setValues] = useState([true, false, false, false, false]);

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleChangeValue = useCallback((index) => {
    setValues((oldValues) => {
      const newValues = [...oldValues];
      newValues[index] = !newValues[index];
      return newValues;
    });
  }, []);

  return (
    <Scaffold bgcolor="raisinBlack" height="100vh">
      <Scaffold.Header>
        <Stack
          px={3}
          pb={1}
          pt={3}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <IconButton size="small" color="isabelline" onClick={handleBack}>
            <ArrowLeftIcon />
          </IconButton>
          <Typography
            color="isabelline"
            fontSize={14}
            lineHeight={1}
            flex={1}
            textAlign="center"
          >
            Learn about your journey
          </Typography>
          <Box width={26} />
        </Stack>
      </Scaffold.Header>
      <Scaffold.Content mt={1} pt={2}>
        <img
          src={imgGuide}
          alt="branding"
          style={{
            width: '100%',
          }}
        />
        <Box mt={4} px={3}>
          <Typography fontSize={32} lineHeight={1.25} color="isabelline">
            Your Journey as an{' '}
            <Typography
              component="span"
              fontSize={32}
              lineHeight={1.25}
              color="beauBlue"
            >
              Oily Ambassador!
            </Typography>
          </Typography>
          <Typography mt={2} fontSize={22} color="isabelline">
            Turning Used Cooking Oil into Environmental Impact!
          </Typography>
          <Typography mt={2} fontSize={14} color="isabelline">
            With Oily you&apos;ll become a vital part of the used cooking oil
            (UCO) recycling movement. As you progress through different levels,
            you&apos;ll learn about the untapped potential of UCO, help map
            restaurants that could join our recycling network, and make a real
            difference in your community!
          </Typography>
          <Typography
            mt={4}
            mb={1}
            color="isabelline"
            fontSize={22}
            lineHeight={1.5}
          >
            Your Oily Ambassador journey is filled with meaningful actions and
            rewards:
          </Typography>
          <Box component="ul" mt={2} mb={0}>
            <Typography component="li" fontSize={16} color="isabelline">
              <b>Learn about Used Cooking Oil</b> and how we&apos;re expanding
              it&apos;s circular economy.
            </Typography>
            <Typography component="li" fontSize={16} color="isabelline">
              <b>Map restaurants in your area</b> that aren&apos;t yet recycling
              their cooking oil.
            </Typography>
            <Typography component="li" fontSize={16} color="isabelline">
              <b>Track your impact</b> and unlock new features as you progress!
            </Typography>
          </Box>
          <Typography mt={1} mb={1} fontSize={14} color="isabelline">
            Each step you take brings more restaurants into the UCO recycling
            network and strengthens this growing sustainable economy where you
            are key!
          </Typography>
          <Typography mt={4} fontSize={20} color="isabelline" lineHeight={1.4}>
            Ready to start making an impact?
          </Typography>
          <Typography
            mt={3}
            fontSize={26}
            fontWeight={600}
            color="isabelline"
            lineHeight={1.5}
          >
            Your Oily Journey:
          </Typography>
          <Typography fontSize={26} color="beauBlue" lineHeight={1.5}>
            Turn Used Cooking Oil into Impact & Rewards!
          </Typography>
          <Box mt={4} py={4}>
            <LevelGuide
              title="Level 0-4"
              subtitle="Become a Sustainability Pioneer"
              summary="Start your journey by discovering the untapped potential of UCO recycling! Every lesson you take earns XP, which you can redeem for $B3TR tokens from level 3 onwards."
              details={
                <>
                  <Typography
                    fontSize={14}
                    fontWeight={700}
                    lineHeight={1.75}
                    color="isabelline"
                  >
                    Your journey begins here:
                  </Typography>
                  <Box mt={2} mb={0} component="ul">
                    <Typography
                      component="li"
                      fontSize={14}
                      lineHeight={1.75}
                      color="isabelline"
                      mb={2}
                    >
                      <b>Power Up Your Knowledge:</b> Dive into learning lessons
                      about UCO recycling and its impact on our future. Every
                      completed challenge earns you XP - the more you learn, the
                      more you earn!
                    </Typography>
                    <Typography
                      component="li"
                      fontSize={14}
                      lineHeight={1.75}
                      color="isabelline"
                      mb={2}
                    >
                      <b>Claim Your First Digital Badge:</b> Welcome the Oily
                      Ambassador NFTs: a unique souldbound digital badge that
                      aren&apos;t just for show - they unlock increasing rewards
                      and benefits as you progress through levels!
                    </Typography>
                    <Typography
                      component="li"
                      fontSize={14}
                      lineHeight={1.75}
                      color="isabelline"
                    >
                      <b>Join the Weekly Adventure:</b> Get your first taste of
                      rewards with weekly $B3TR raffles. The higher your voting
                      allocation, the higher the chance to win! Every Sunday: 1
                      lucky community member wins 100 $B3TR! Every 4 weeks: 1
                      lucky voter wins 1000 $B3TR for voting Oily for 4
                      consecutive weeks on VeBetterDAO!
                    </Typography>
                  </Box>
                </>
              }
              open={values[0]}
              onToggle={() => handleChangeValue(0)}
            />
            <Divider />
            <LevelGuide
              title="Level 5"
              subtitle="Expand Your Influence"
              summary="Ready to put your knowledge into action?"
              details={
                <>
                  <Typography
                    fontSize={14}
                    fontWeight={700}
                    lineHeight={1.75}
                    color="isabelline"
                  >
                    First, make sure you&apos;ve met these milestones:
                  </Typography>
                  <Box mt={2} mb={0} component="ul">
                    <Typography
                      component="li"
                      fontSize={14}
                      lineHeight={1.75}
                      color="isabelline"
                      mb={1}
                    >
                      <b>Be active for +30 days w/</b> active logins on Oily
                    </Typography>
                    <Typography
                      component="li"
                      fontSize={14}
                      lineHeight={1.75}
                      color="isabelline"
                      mb={1}
                    >
                      <b>Earn +5000 XPs</b> through learning and engagement
                    </Typography>
                    <Typography
                      component="li"
                      fontSize={14}
                      lineHeight={1.75}
                      color="isabelline"
                    >
                      <b>Prepare to lock 3000 XPs</b> until you reach level 6 -
                      as a commitment!
                    </Typography>
                  </Box>
                  <Typography
                    my={2}
                    fontSize={14}
                    lineHeight={1.75}
                    color="beauBlue"
                  >
                    Why will 3000 XPs be locked?
                  </Typography>
                  <Typography
                    fontSize={14}
                    fontWeight={700}
                    lineHeight={1.75}
                    color="isabelline"
                  >
                    Once qualified, you&apos;ll open a new pandora box! Level 5
                    let&apos;s you:
                  </Typography>
                  <Box mt={1} mb={0} component="ul">
                    <Typography
                      component="li"
                      fontSize={14}
                      lineHeight={1.75}
                      color="isabelline"
                      mb={1}
                    >
                      <b>Become a UCO Scout:</b> Your mission begins!
                    </Typography>
                    <Typography
                      component="li"
                      fontSize={14}
                      lineHeight={1.75}
                      color="isabelline"
                      mb={1}
                    >
                      <b>Use our initial mapping tools to:</b> discover
                      restaurants that aren&apos;t recycling their UCO yet.
                      Start with mapping up to 10 restaurants in your area
                      within your local radius.
                    </Typography>
                    <Typography
                      component="li"
                      fontSize={14}
                      lineHeight={1.75}
                      color="isabelline"
                    >
                      <b>Build Your Green Reputation:</b> Every verified
                      location boosts your credibility and reward potential. The
                      more accurate data you provide, the faster you&apos;ll
                      climb to higher reward levels!
                    </Typography>
                  </Box>
                </>
              }
              open={values[1]}
              onToggle={() => handleChangeValue(1)}
            />
            <Divider />
            <LevelGuide
              title="Level 6"
              subtitle="Certified Impact Maker"
              summary="Your dedication unlocks enhanced rewards!"
              details={
                <>
                  <Typography
                    fontSize={14}
                    fontWeight={700}
                    lineHeight={1.75}
                    color="isabelline"
                  >
                    As a Verified Mapper, you&apos;ll get:
                  </Typography>
                  <Box mt={2} mb={0} component="ul">
                    <Typography
                      component="li"
                      fontSize={14}
                      lineHeight={1.75}
                      color="isabelline"
                      mb={2}
                    >
                      <b>Pro Mapping Powers:</b> Advanced mapping features mean
                      more opportunities to find unrecycled UCO and earn
                      elevated rewards.
                    </Typography>
                    <Typography
                      component="li"
                      fontSize={14}
                      lineHeight={1.75}
                      color="isabelline"
                      mb={2}
                    >
                      <b>Verification Authority:</b> Your photo verification of
                      UCO locations earns you bonus XP - because accuracy
                      matters and deserves extra rewards!
                    </Typography>
                    <Typography
                      component="li"
                      fontSize={14}
                      lineHeight={1.75}
                      color="isabelline"
                    >
                      <b>Restaurant Connection:</b> Every new restaurant you
                      bring to our network is a win-win: more sustainability
                      impact and more rewards for you!
                    </Typography>
                  </Box>
                </>
              }
              open={values[2]}
              onToggle={() => handleChangeValue(2)}
            />
            <Divider />
            <LevelGuide
              title="Level 7-8"
              subtitle="Rise to UCO Legend"
              summary="Welcome to the ranks of top contributors with premium perks!"
              details={
                <>
                  <Box mb={0} component="ul">
                    <Typography
                      component="li"
                      fontSize={14}
                      lineHeight={1.75}
                      color="isabelline"
                      mb={2}
                    >
                      <b>Expand Your Territory:</b> Wider mapping radius means
                      more opportunities to find unrecycled UCO and earn
                      enhanced rewards without having to move so much!
                    </Typography>
                    <Typography
                      component="li"
                      fontSize={14}
                      lineHeight={1.75}
                      color="isabelline"
                      mb={2}
                    >
                      <b>Connect with WMOs:</b> Create vital links in the UCO
                      recycling chain and help us expand the network!
                    </Typography>
                    <Typography
                      component="li"
                      fontSize={14}
                      lineHeight={1.75}
                      color="isabelline"
                    >
                      <b>Early Bird Advantages:</b> Your high-level status earns
                      you priority access to our token airdrops and special
                      reward allocations!
                    </Typography>
                  </Box>
                </>
              }
              open={values[3]}
              onToggle={() => handleChangeValue(3)}
            />
            <Divider />
            <LevelGuide
              title="Level 9-10"
              subtitle="Join the Elite Force"
              summary="Reach the pinnacle of impact and rewards!"
              details={
                <>
                  <Box mb={0} component="ul">
                    <Typography
                      component="li"
                      fontSize={14}
                      lineHeight={1.75}
                      color="isabelline"
                      mb={2}
                    >
                      <b>Century Club (100+ Verified Locations):</b> Map over
                      100 locations to unlock our highest reward tier!
                    </Typography>
                    <Typography
                      component="li"
                      fontSize={14}
                      lineHeight={1.75}
                      color="isabelline"
                      mb={2}
                    >
                      <b>Ambassador Essentials:</b> Your elite status comes with
                      an exclusive kit and maximum reward multipliers on all
                      your contributions.
                    </Typography>
                    <Typography
                      component="li"
                      fontSize={14}
                      lineHeight={1.75}
                      color="isabelline"
                    >
                      <b>Spread the Word:</b> Earn bonus rewards for growing our
                      community and inspiring others to join the UCO revolution.
                    </Typography>
                    <Typography
                      component="li"
                      fontSize={14}
                      lineHeight={1.75}
                      color="isabelline"
                    >
                      <b>Supercharged Progress (1.3x XP):</b> Maximum XP
                      multiplier means maximum earnings and multiple rewards on
                      every action!
                    </Typography>
                  </Box>
                </>
              }
              open={values[4]}
              onToggle={() => handleChangeValue(4)}
            />
          </Box>
          <Box
            mt={6}
            mb={9}
            p={3}
            sx={{
              borderWidth: 1,
              borderRadius: 4,
              borderStyle: 'solid',
              borderColor: 'purpleNavy',
            }}
          >
            <Typography
              fontSize={20}
              fontWeight={500}
              lineHeight={1.4}
              color="beauBlue"
              textAlign="center"
            >
              Ready to start mapping and earning?
            </Typography>
            <Typography
              fontSize={14}
              lineHeight={1.75}
              color="isabelline"
              textAlign="center"
            >
              <b>Remember:</b> Your level boosts your rewards—discovering
              unrecycled UCO sources increases both earnings and sustainability
              impact. The more restaurants you bring into the ecosystem, the
              greater your rewards!
            </Typography>
            <Box mt={4}>
              <Button
                variant="contained"
                color="icterine"
                fullWidth
                component={NavLink}
                to="/progress"
              >
                View my progress
              </Button>
            </Box>
          </Box>
        </Box>
      </Scaffold.Content>
      <Scaffold.Footer></Scaffold.Footer>
    </Scaffold>
  );
};

LevelGuide.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  summary: PropTypes.string,
  details: PropTypes.node,
  open: PropTypes.bool,
  onToggle: PropTypes.func,
};
