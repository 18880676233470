export const MuiInputBase = {
  styleOverrides: {
    root: () => ({
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
    }),
  },
};

export const MuiFilledInput = {
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: `${theme.palette.isabelline} !important`,
      borderRadius: 8,
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    }),
    input: {
      padding: '10px 16px',
    },
  },
};

export const MuiTextField = {
  defaultProps: {
    InputLabelProps: {
      shrink: true,
    },
  },
  styleOverrides: {},
  variants: [
    {
      props: {
        variant: 'standard',
        color: 'dark',
      },
      style: ({ theme }) => ({
        '& input': {
          fontSize: 22,
          fontWeight: 400,
          color: theme.palette.isabelline,
          paddingTop: 10,
          paddingBottom: 16,
          lineHeight: '32px',
          height: 32,
        },
        '& .MuiInput-root.Mui-error input': {
          color: theme.palette.error[500],
        },
        '& .MuiInput-root::before': {
          borderBottomColor: `${theme.palette.shadowBlue} !important`,
        },
        '& .MuiInput-root.Mui-error::before': {
          borderBottomColor: `${theme.palette.error[500]} !important`,
        },
        '& .MuiInputLabel-root': {
          color: theme.palette.isabelline,
          fontSize: 10,
          lineHeight: 1.6,
          transform: 'none',
          '&.Mui-error': {
            color: theme.palette.error[500],
          },
        },
        '& .MuiFormLabel-asterisk': {
          color: theme.palette.error[600],
          fontSize: 20,
          lineHeight: '16px',
          verticalAlign: 'middle',
        },
      }),
    },
    {
      props: {
        variant: 'standard',
        color: 'light',
      },
      style: ({ theme }) => ({
        '& .MuiInput-input': {
          fontSize: 22,
          fontWeight: 400,
          lineHeight: '32px',
          height: 32,
          color: theme.palette.raisinBlack,
          paddingTop: '10px !important',
          paddingBottom: '16px !important',
        },
        '& .MuiInput-root.Mui-error .MuiInput-input': {
          color: theme.palette.error[500],
        },
        '& .MuiInput-root::before': {
          borderBottomColor: `${theme.palette.shadowBlue} !important`,
        },
        '& .MuiInput-root.Mui-error::before': {
          borderBottomColor: `${theme.palette.error[500]} !important`,
        },
        '& .MuiInputLabel-root': {
          color: theme.palette.raisinBlack,
          fontSize: 10,
          lineHeight: 1.6,
          transform: 'none',
          '&.Mui-error': {
            color: theme.palette.error[500],
          },
        },
        '& .MuiFormLabel-asterisk': {
          color: theme.palette.error[600],
          fontSize: 20,
          lineHeight: '16px',
          verticalAlign: 'middle',
        },
        '& .MuiSelect-icon': {
          top: 16,
        },
        '& .MuiInput-root.Mui-error .MuiSelect-icon': {
          color: theme.palette.error[500],
        },
        '& .MuiFormHelperText-root': {
          paddingTop: 1,
          fontSize: 14,
          fontWeight: 400,
          lineHeight: 1.75,
        },
      }),
    },
    {
      props: {
        variant: 'standard',
        color: 'redeem',
      },
      style: ({ theme }) => ({
        '& .MuiInput-input': {
          fontSize: 22,
          fontWeight: 650,
          lineHeight: '32px',
          height: 32,
          color: theme.palette.purpleNavy,
          paddingTop: '10px !important',
          paddingBottom: '16px !important',
        },
        '& .MuiInputAdornment-root': {
          paddingTop: '10px !important',
          paddingBottom: '16px !important',

          '& .MuiTypography-root': {
            fontSize: 22,
            fontWeight: 650,
            lineHeight: '32px',
            height: 32,
            color: theme.palette.purpleNavy,
          },
        },
        '& .MuiInput-root.Mui-error .MuiInput-input': {
          color: theme.palette.error[500],
        },
        '& .MuiInput-root::before': {
          borderBottomColor: `${theme.palette.shadowBlue} !important`,
          borderBottomWidth: `2px !important`,
        },
        '& .MuiInput-root.Mui-error::before': {
          borderBottomColor: `${theme.palette.error[500]} !important`,
        },
        '& .MuiInputLabel-root': {
          color: theme.palette.raisinBlack,
          fontSize: 10,
          lineHeight: 1.6,
          transform: 'none',
          '&.Mui-error': {
            color: theme.palette.error[500],
          },
        },
        '& .MuiFormLabel-asterisk': {
          color: theme.palette.error[600],
          fontSize: 20,
          lineHeight: '16px',
          verticalAlign: 'middle',
        },
        '& .MuiSelect-icon': {
          top: 16,
        },
        '& .MuiInput-root.Mui-error .MuiSelect-icon': {
          color: theme.palette.error[500],
        },
        '& .MuiFormHelperText-root': {
          paddingTop: 8,
          fontSize: 14,
          fontWeight: 400,
          lineHeight: 1.75,
          color: `${theme.palette.shadowBlue} !important`,
        },
      }),
    },
  ],
};
