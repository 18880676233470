import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { appSlice, updateAppStatus } from './app.slice';
import { authSlice } from './auth.slice';
import { profileSlice } from './profile.slice';
import { walletSlice } from './wallet.slice';
import { variablesSlice } from './variables.slice';
import { placesSlice } from './places.slice';

import { apiGetL2ESummary } from '../apis/l2e';
import { apiLogin } from '../apis/auth';
import { apiGetAccountLevel, apiGetProfile } from '../apis/profile';
import { apiGetPlaces } from '../apis/m2e';
import { apiDisconnectWallet } from '../apis/wallet';

const persistConfig = {
  key: 'uco-oily-v2',
  storage,
  blacklist: ['app'],
};

const rootReducer = combineReducers({
  app: appSlice.reducer,
  auth: authSlice.reducer,
  profile: profileSlice.reducer,
  wallet: walletSlice.reducer,
  variables: variablesSlice.reducer,
  places: placesSlice.reducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
});

export const persistor = persistStore(store, {}, () => {
  console.log('Initializing...');

  (async () => {
    const { account, chainName: chain } = store.getState().wallet;
    if (account && chain) {
      await apiGetL2ESummary();
      const result = await apiLogin({
        wallet_address: account,
        chain: chain,
      });
      if (result === 'verified') {
        await apiGetProfile({
          need_balance: true,
          need_ratio: true,
        });
        await apiGetAccountLevel();
        await apiGetPlaces({
          latitude: 1,
          longitude: 1,
          radius: 1,
        });
      } else {
        await apiDisconnectWallet();
      }
    } else {
      await apiDisconnectWallet();
    }

    store.dispatch(
      updateAppStatus({
        isReady: true,
        isGlobalLoading: false,
      }),
    );
    console.log('Initialization has been completed');
  })();
});
