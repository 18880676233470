import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const ProtectedRoute = ({ children }) => {
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { account } = useSelector((state) => state.wallet);

  if (!isLoggedIn || !account) {
    return <Navigate to="/welcome" replace />;
  }

  return children;
};

ProtectedRoute.propTypes = {
  children: PropTypes.any,
};
