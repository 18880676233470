import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';
import {
  Box,
  ButtonBase,
  IconButton,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { XpGauge } from '../../../components/core/XpGauge';
import {
  AccountLvl0InfoModal,
  AccountLvl1InfoModal,
  AccountLvl2InfoModal,
  AccountLvl3InfoModal,
  AccountLvl4InfoModal,
  AccountLvl5InfoModal,
  AccountLvl6InfoModal,
  AccountLvl7InfoModal,
  AccountLvl8InfoModal,
  AccountLvl9InfoModal,
  AccountLvl10InfoModal,
} from '../../../components/modals/AccountModals';
import { CopyIcon } from 'lucide-react';
import { useSelector } from 'react-redux';
import { enqueueSnackbar } from 'notistack';

import imgBadgeLvl0 from '../../../assets/badges/badge-lvl0.png';
import imgBadgeLvl1 from '../../../assets/badges/badge-lvl1.png';
import imgBadgeLvl2 from '../../../assets/badges/badge-lvl2.png';
import imgBadgeLvl3 from '../../../assets/badges/badge-lvl3.png';
import imgBadgeLvl4 from '../../../assets/badges/badge-lvl4.png';
import imgBadgeLvl5 from '../../../assets/badges/badge-lvl5.png';
import imgBadgeLvl6 from '../../../assets/badges/badge-lvl6.png';
import imgBadgeLvl7 from '../../../assets/badges/badge-lvl7.png';
import imgBadgeLvl8 from '../../../assets/badges/badge-lvl8.png';
import imgBadgeLvl9 from '../../../assets/badges/badge-lvl9.png';
import imgBadgeLvl10 from '../../../assets/badges/badge-lvl10.png';

const BadgeImage = styled('img')({});

const ProgressRoute = styled(Box)(({ theme, side, current, target }) => ({
  position: 'relative',
  height: 70,
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  borderWidth: 40,
  borderStyle: 'solid',
  borderColor: 'transparent',
  borderRadius: side === 'left' ? '0 999px 999px 0' : '999px 0 0 999px',
  ...(side === 'left' && {
    transform: 'translateX(0)',
    justifyContent: 'flex-start',
    borderLeft: 'none',
  }),
  ...(side === 'right' && {
    transform: 'translateX(-110px)',
    justifyContent: 'flex-end',
    borderRight: 'none',
  }),
  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    top: -40,
    left: 0,
    right: 0,
    bottom: -40,
    borderRadius: side === 'left' ? '0 999px 999px 0' : '999px 0 0 999px',
    pointerEvents: 'none',
  },
  '&::before': {
    borderWidth: 40,
    borderStyle: 'solid',
    borderColor:
      target <= current ? theme.palette.green[600] : theme.palette.gray[700],
    borderLeft: side === 'left' ? 'none' : undefined,
    borderRight: side === 'right' ? 'none' : undefined,
    clipPath: 'polygon(0 0, 100% 0, 100% 50%, 0 50%)',
  },
  '&::after': {
    borderWidth: 40,
    borderStyle: 'solid',
    borderColor:
      target >= current ? theme.palette.gray[700] : theme.palette.green[600],
    borderLeft: side === 'left' ? 'none' : undefined,
    borderRight: side === 'right' ? 'none' : undefined,
    clipPath: 'polygon(0 49%, 100% 49%, 100% 100%, 0 100%)',
  },
  '&:not(:first-of-type)': {
    marginTop: -40,
  },
  '&:last-of-type': {
    '&::before, &::after': {
      borderColor: 'transparent',
    },
  },
  '& .MuiButtonBase-root': {
    position: 'absolute',
    borderRadius: '100%',
    ...(side === 'left' && {
      top: -44,
      left: -25,
    }),
    ...(side === 'right' && {
      top: -44,
      right: -25,
    }),
    '& img': {
      width: 50.14,
      height: 52,
      ...(target > current && {
        filter: 'grayscale(100%)',
      }),
    },
  },
  '& .MuiTypography-root': {
    display: 'inline-block',
    fontSize: 14,
    color: theme.palette.isabelline,
    paddingTop: 12,
    ...(side === 'left' && {
      transform: 'translateX(-50%)',
    }),
    ...(side === 'right' && {
      transform: 'translateX(50%)',
    }),
  },
}));

const BadgeButton = ({ alt, src, onClick }) => {
  return (
    <ButtonBase onClick={onClick}>
      <BadgeImage alt={alt} src={src} />
    </ButtonBase>
  );
};

export const ProgressPart = () => {
  const {
    level,
    xp: currentXp,
    lockedXp,
    ratio,
  } = useSelector((state) => state.profile);
  const { account } = useSelector((state) => state.wallet);
  const xp = currentXp - lockedXp;

  const [activeLevel, setActiveLevel] = useState(false);

  const handleShowLevelInfo = useCallback((level) => {
    return () => {
      setActiveLevel(level);
    };
  }, []);

  const handleHideLevelInfo = useCallback(() => {
    setActiveLevel(false);
  }, []);

  const handleCopyWallet = useCallback(() => {
    if (account) {
      copy(account);
      enqueueSnackbar({
        message: 'Your wallet address copied to clipboard',
        variant: 'success',
      });
    }
  }, [account]);

  return (
    <Box pt={1}>
      <Stack
        direction="column"
        pt={2}
        pl="calc(24% + 34px)"
        pr="calc(24% - 34px)"
        spacing={0}
      >
        <ProgressRoute side="left" target={0} current={level}>
          <BadgeButton
            alt="Badge Lvl 0"
            src={imgBadgeLvl0}
            onClick={handleShowLevelInfo(0)}
          />
          <Typography>
            <b>Level 0:</b> Newcomer
          </Typography>
        </ProgressRoute>
        <ProgressRoute side="right" target={1} current={level}>
          <BadgeButton
            alt="Badge Lvl 1"
            src={imgBadgeLvl1}
            onClick={handleShowLevelInfo(1)}
          />
          <Typography>
            <b>L1:</b> Initiate
          </Typography>
        </ProgressRoute>
        <ProgressRoute side="left" target={2} current={level}>
          <BadgeButton
            alt="Badge Lvl 2"
            src={imgBadgeLvl2}
            onClick={handleShowLevelInfo(2)}
          />
          <Typography>
            <b>L2:</b> Green Apprentice
          </Typography>
        </ProgressRoute>
        <ProgressRoute side="right" target={3} current={level}>
          <BadgeButton
            alt="Badge Lvl 3"
            src={imgBadgeLvl3}
            onClick={handleShowLevelInfo(3)}
          />
          <Typography>
            <b>L3:</b> Sustainability Explorer
          </Typography>
        </ProgressRoute>
        <ProgressRoute side="left" target={4} current={level}>
          <BadgeButton
            alt="Badge Lvl 4"
            src={imgBadgeLvl4}
            onClick={handleShowLevelInfo(4)}
          />
          <Typography>
            <b>L4:</b> UCO Enthusiast
          </Typography>
        </ProgressRoute>
        <ProgressRoute side="right" target={5} current={level}>
          <BadgeButton
            alt="Badge Lvl 5"
            src={imgBadgeLvl5}
            onClick={handleShowLevelInfo(5)}
          />
          <Typography>
            <b>L5:</b> Map Explorer
          </Typography>
        </ProgressRoute>
        <ProgressRoute side="left" target={6} current={level}>
          <BadgeButton
            alt="Badge Lvl 6"
            src={imgBadgeLvl6}
            onClick={handleShowLevelInfo(6)}
          />
          <Typography>
            <b>L6:</b> Verified Mapper
          </Typography>
        </ProgressRoute>
        <ProgressRoute side="right" target={7} current={level}>
          <BadgeButton
            alt="Badge Lvl 7"
            src={imgBadgeLvl7}
            onClick={handleShowLevelInfo(7)}
          />
          <Typography>
            <b>L7:</b> UCO Champion
          </Typography>
        </ProgressRoute>
        <ProgressRoute side="left" target={8} current={level}>
          <BadgeButton
            alt="Badge Lvl 8"
            src={imgBadgeLvl8}
            onClick={handleShowLevelInfo(8)}
          />
          <Typography>
            <b>L8:</b> UCO Legend
          </Typography>
        </ProgressRoute>
        <ProgressRoute side="right" target={9} current={level}>
          <BadgeButton
            alt="Badge Lvl 9"
            src={imgBadgeLvl9}
            onClick={handleShowLevelInfo(9)}
          />
          <Typography>
            <b>L9:</b> Mapping Master
          </Typography>
        </ProgressRoute>
        <ProgressRoute side="left" target={10} current={level}>
          <BadgeButton
            alt="Badge Lvl 10"
            src={imgBadgeLvl10}
            onClick={handleShowLevelInfo(10)}
          />
          <Typography>
            <b>L10:</b> Oily Ambassador
          </Typography>
        </ProgressRoute>
      </Stack>
      <Box px={4} mt={-4}>
        <Typography
          fontSize={16}
          fontWeight={700}
          lineHeight={1.75}
          color="isabelline"
        >
          Your wallet address
        </Typography>
        <Stack direction="row" alignItems="center" mt={0.5}>
          <Typography fontSize={14} lineHeight={1} color="isabelline" mr={1}>
            {account?.substr(0, 10)}...{account?.substr(-8, 8)}
          </Typography>
          <IconButton
            size="small"
            color="isabelline"
            onClick={handleCopyWallet}
          >
            <CopyIcon />
          </IconButton>
        </Stack>
      </Box>
      <Box px={4} mt={4}>
        <Typography
          fontSize={16}
          fontWeight={700}
          lineHeight={1.75}
          color="isabelline"
        >
          Current balance
        </Typography>
        <XpGauge xp={(xp ?? 0).toFixed(0)} />
        <Typography mt={0.5} fontSize={14} lineHeight={1.25} color="beauBlue">
          {ratio ? (
            <>~ {(((xp ? xp : 0) * ratio) / 100).toFixed(3)} B3TR</>
          ) : (
            <>loading conversation ratio</>
          )}
        </Typography>
        <Typography mt={0.5} fontSize={10} lineHeight={1.25} color="shadowBlue">
          {ratio ? (
            <>~{(1 / (ratio / 100)).toLocaleString()} XP per 1 B3TR</>
          ) : (
            <>loading conversation ratio</>
          )}
        </Typography>
      </Box>
      <Box height={60} />
      <AccountLvl0InfoModal
        open={activeLevel === 0}
        onClose={handleHideLevelInfo}
      />
      <AccountLvl1InfoModal
        open={activeLevel === 1}
        onClose={handleHideLevelInfo}
      />
      <AccountLvl2InfoModal
        open={activeLevel === 2}
        onClose={handleHideLevelInfo}
      />
      <AccountLvl3InfoModal
        open={activeLevel === 3}
        onClose={handleHideLevelInfo}
      />
      <AccountLvl4InfoModal
        open={activeLevel === 4}
        onClose={handleHideLevelInfo}
      />
      <AccountLvl5InfoModal
        open={activeLevel === 5}
        onClose={handleHideLevelInfo}
      />
      <AccountLvl6InfoModal
        open={activeLevel === 6}
        onClose={handleHideLevelInfo}
      />
      <AccountLvl7InfoModal
        open={activeLevel === 7}
        onClose={handleHideLevelInfo}
      />
      <AccountLvl8InfoModal
        open={activeLevel === 8}
        onClose={handleHideLevelInfo}
      />
      <AccountLvl9InfoModal
        open={activeLevel === 9}
        onClose={handleHideLevelInfo}
      />
      <AccountLvl10InfoModal
        open={activeLevel === 10}
        onClose={handleHideLevelInfo}
      />
    </Box>
  );
};

BadgeButton.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
  onClick: PropTypes.func,
};
