import React, { useCallback, useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';
import { Scaffold } from '../../components/layouts/Scaffold';
import {
  Box,
  Button,
  ButtonBase,
  IconButton,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { XpGauge } from '../../components/core/XpGauge';
import { XpIndicator } from '../../components/core/XpIndicator';
import { EnergySymbol } from '../../components/core/EnergySymbol';
import {
  ArrowLeftIcon,
  ClipboardCheckIcon,
  CopyIcon,
  InfoIcon,
} from 'lucide-react';
import { enqueueSnackbar } from 'notistack';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  apiClaimReward,
  apiGetMinimumICPRate,
  apiGetMinimumXDCRate,
} from '../../apis/redeem';
import { apiGetProfile } from '../../apis/profile';
import { updateAppStatus } from '../../redux/app.slice';

import { XpHistoryPart } from './partials/XpHistoryPart';
import { RedeemHistoryPart } from './partials/RedeemHistoryPart';

export const RedeemPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    xp: currentXp,
    totalXp,
    ratio,
    level,
    lockedXp,
    vechain_account,
    xdc_account,
    icp_account,
  } = useSelector((state) => state.profile);
  const { chainName } = useSelector((state) => state.wallet);
  const { isGlobalLoading } = useSelector((state) => state.app);
  const xp = currentXp - lockedXp;

  const queryClient = useQueryClient();

  const [tab, setTab] = useState('redeem');
  const [copied, setCopied] = useState(false);
  const [amount, setAmount] = useState('0');
  const [b3tr, setB3tr] = useState('0');
  const [remaining, setRemaining] = useState('0');
  const [open, setOpen] = useState(false);
  const [guide, setGuide] = useState(false);

  const { data: minXdcAmount } = useQuery({
    queryKey: ['info', 'min-xdc-rate'],
    queryFn: apiGetMinimumXDCRate,
    enabled: chainName === 'xdc',
  });
  const { data: minIcpAmount } = useQuery({
    queryKey: ['info', 'min-icp-rate'],
    queryFn: apiGetMinimumICPRate,
    enabled: chainName === 'nfid',
  });

  const handleBack = useCallback(() => {
    navigate('/home');
  }, [navigate]);

  const handleCopyAddress = useCallback((value) => {
    if (value) {
      copy(value);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, []);

  const handleChangeAmount = useCallback((event) => {
    setAmount(event.target.value);
  }, []);

  const handleAdjustAmount = useCallback(() => {
    const value = parseFloat(amount);
    if (isNaN(value)) {
      return;
    }

    if (value > xp - 200) {
      const max = xp - 200 > 0 ? xp - 200 : 0;
      setAmount(max.toFixed(0));
      enqueueSnackbar({
        message: `You can only redeem up to ${max.toFixed(0)} XP to keep 200 XP for leveling up.`,
        variant: 'error',
      });
    }
  }, [amount, xp]);

  const handleSetAmount = useCallback(
    (value) => {
      const result = Math.ceil(value * (xp - 200));
      setAmount(result);
    },
    [xp],
  );

  const handleRedeem = useCallback(async () => {
    setOpen(false);
    if (!xp || xp <= 0 || isGlobalLoading || !ratio) {
      return;
    }

    const value = parseFloat(amount);
    if (isNaN(value)) {
      return;
    }

    if (chainName === 'xdc' || chainName === 'nfid') {
      const b3tr = (value / 100) * ratio;
      const min = chainName === 'xdc' ? minXdcAmount : minIcpAmount;
      if (b3tr < min) {
        const xp = Math.ceil((ratio / 100) * min);

        enqueueSnackbar({
          message: `You require ${xp.toFixed(0)} XP or more`,
          variant: 'error',
        });
        return;
      }
    }

    dispatch(
      updateAppStatus({
        isGlobalLoading: true,
      }),
    );
    const result = await apiClaimReward({
      amount: value,
    });
    if (result && result > 0) {
      enqueueSnackbar({
        message: `You have converted ${value.toFixed(0)} XP into ${Number(result).toFixed(2)} B3TR`,
        variant: 'success',
      });
      await queryClient.invalidateQueries('transactions');
    }
    dispatch(
      updateAppStatus({
        isGlobalLoading: false,
      }),
    );
  }, [
    xp,
    ratio,
    amount,
    isGlobalLoading,
    dispatch,
    queryClient,
    chainName,
    minXdcAmount,
    minIcpAmount,
  ]);

  const handleChangeTab = useCallback(
    (target) => {
      return () => {
        if (tab !== target) {
          setTab(target);
        }
      };
    },
    [tab],
  );

  const handleToggleGuide = useCallback(() => {
    setGuide((value) => !value);
  }, []);

  useEffect(() => {
    apiGetProfile({
      need_balance: true,
      need_ratio: true,
    });
  }, []);

  useEffect(() => {
    if (level < 3) {
      navigate('/home');
    }
  }, [level, navigate]);

  useEffect(() => {
    const value = parseFloat(amount);
    if (isNaN(value)) {
      return;
    }

    let result = ((value / 100) * ratio).toFixed(2);
    setB3tr(result);
  }, [ratio, amount]);

  useEffect(() => {
    const value = parseFloat(amount);
    if (isNaN(value)) {
      return;
    }

    let remaining = xp - 200 - value;
    setRemaining(remaining < 0 ? 0 : remaining);

    if (value > xp - 200) {
      setAmount((xp - 200).toFixed(0));
    }
  }, [amount, xp]);

  return (
    <Scaffold height="100vh" bgcolor="raisinBlack">
      <Scaffold.Header>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          pt={1}
          pb={2}
          minHeight={56}
        >
          <IconButton
            color="isabelline"
            size="small"
            sx={{ mr: 2 }}
            onClick={handleBack}
          >
            <ArrowLeftIcon />
          </IconButton>
          <XpIndicator onClick={() => setOpen(true)} />
        </Stack>
      </Scaffold.Header>
      <Scaffold.Content>
        <Box px={3} mt={2}>
          <Typography
            fontSize={16}
            fontWeight={700}
            color="isabelline"
            lineHeight={1.75}
          >
            Current available balance
          </Typography>
          <Box>
            <XpGauge
              xp={xp - 200 > 0 ? (xp - 200).toFixed(0) : 0}
              max={Number(totalXp).toFixed(0)}
              iconProps={{
                width: 24,
                height: 24,
              }}
              xpProps={{
                fontSize: 32,
                lineHeight: 1.25,
                color: 'white',
              }}
              maxProps={{
                fontSize: 32,
                lineHeight: 1.25,
                color: 'shadowBlue',
              }}
            />
          </Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mt={3}
          >
            <Typography fontSize={16} lineHeight={1} color="beauBlue">
              Why will some XP be locked?
            </Typography>
            <IconButton
              size="small"
              color="isabelline"
              onClick={handleToggleGuide}
            >
              <InfoIcon />
            </IconButton>
          </Stack>
          <Box mt={4}>
            <Button
              variant="contained"
              color="icterine"
              fullWidth
              disabled={xp <= 200 && false}
              onClick={() => setOpen(true)}
            >
              Redeem
            </Button>
          </Box>
        </Box>
        <Box mt={5} mx={1}>
          <Stack
            direction="row"
            p={1}
            spacing={1}
            bgcolor="charcoal"
            borderRadius={2}
          >
            <ButtonBase
              sx={{
                flex: 1,
                py: '10px',
                borderRadius: 2,
                bgcolor: tab === 'redeem' ? 'raisinBlack' : 'transparent',
              }}
              onClick={handleChangeTab('redeem')}
            >
              <Typography color="isabelline" fontSize={14} fontWeight={500}>
                Redeem History
              </Typography>
            </ButtonBase>
            <ButtonBase
              sx={{
                flex: 1,
                py: '10px',
                borderRadius: 2,
                bgcolor: tab === 'xp' ? 'raisinBlack' : 'transparent',
              }}
              onClick={handleChangeTab('xp')}
            >
              <Typography color="isabelline" fontSize={14} fontWeight={500}>
                XPs History
              </Typography>
            </ButtonBase>
          </Stack>
        </Box>
        {tab === 'redeem' ? <RedeemHistoryPart /> : null}
        {tab === 'xp' ? <XpHistoryPart /> : null}
        <Box px={3} mt={4}>
          <Typography
            mt={4}
            fontSize={16}
            fontWeight={700}
            color="isabelline"
            lineHeight={1.75}
          >
            Your wallet address
          </Typography>
          {vechain_account ? (
            <Stack mt={0.5} direction="row" alignItems="center">
              <Typography
                fontSize={14}
                lineHeight={1.7}
                color="beauBlue"
                mr={1}
              >
                Vechain:
              </Typography>
              <Typography
                fontSize={14}
                lineHeight={1.7}
                color="isabelline"
                mr={1}
              >
                {vechain_account?.substr(0, 10)}...
                {vechain_account?.substr(-8, 8)}
              </Typography>
              <IconButton
                size="small"
                color="isabelline"
                onClick={() => handleCopyAddress(vechain_account)}
              >
                {copied ? <ClipboardCheckIcon /> : <CopyIcon />}
              </IconButton>
            </Stack>
          ) : null}
          {xdc_account ? (
            <Stack mt={0.5} direction="row" alignItems="center">
              <Typography
                fontSize={14}
                lineHeight={1.7}
                color="beauBlue"
                mr={1}
              >
                ETH/XDC:
              </Typography>
              <Typography
                fontSize={14}
                lineHeight={1.7}
                color="isabelline"
                mr={1}
              >
                {xdc_account?.substr(0, 10)}...{xdc_account?.substr(-8, 8)}
              </Typography>
              <IconButton
                size="small"
                color="isabelline"
                onClick={() => handleCopyAddress(xdc_account)}
              >
                {copied ? <ClipboardCheckIcon /> : <CopyIcon />}
              </IconButton>
            </Stack>
          ) : null}
          {icp_account ? (
            <Stack mt={0.5} direction="row" alignItems="center">
              <Typography
                fontSize={14}
                lineHeight={1.7}
                color="beauBlue"
                mr={1}
              >
                ICP:
              </Typography>
              <Typography
                fontSize={14}
                lineHeight={1.7}
                color="isabelline"
                mr={1}
              >
                {icp_account?.substr(0, 10)}...{icp_account?.substr(-8, 8)}
              </Typography>
              <IconButton
                size="small"
                color="isabelline"
                onClick={() => handleCopyAddress(icp_account)}
              >
                {copied ? <ClipboardCheckIcon /> : <CopyIcon />}
              </IconButton>
            </Stack>
          ) : null}
        </Box>
        <Modal open={open} onClose={() => setOpen(false)}>
          <Box>
            <Typography fontSize={32} lineHeight={1.25} color="raisinBlack">
              Set a value to redeem your <b>XPs</b>
            </Typography>
            <Typography
              mt={1}
              fontSize={16}
              lineHeight={1.75}
              color="raisinBlack"
            >
              We will block <b>200 XP</b> so you can keep leveling up!
            </Typography>
            <Box mt={4}>
              <TextField
                variant="standard"
                color="redeem"
                fullWidth
                type="number"
                label="YOU PAY"
                placeholder="0"
                value={amount}
                error={parseFloat(amount) > xp - 200}
                onChange={handleChangeAmount}
                onBlur={handleAdjustAmount}
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <Stack direction="row" alignItems="center">
                          <EnergySymbol
                            color={theme.palette.purpleNavy}
                            width={24}
                            height={24}
                          />
                          <Typography>XP</Typography>
                        </Stack>
                      </InputAdornment>
                    ),
                  },
                }}
                helperText={
                  <>
                    Remaining Balance: <b>{remaining} XP</b> (already minus{' '}
                    <b>200XP</b> to keep)
                  </>
                }
              />
            </Box>
            <Box mt={4}>
              <TextField
                variant="standard"
                color="redeem"
                fullWidth
                type="number"
                label="YOU RECEIVE"
                placeholder="0"
                value={b3tr}
                slotProps={{
                  input: {
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">B3TR</InputAdornment>
                    ),
                  },
                }}
                helperText={
                  ratio ? (
                    <>
                      <b>~{(1 / (ratio / 100)).toLocaleString()} XP</b>
                      &nbsp; per <b>1 B3TR</b>
                    </>
                  ) : (
                    <>loading conversation ratio</>
                  )
                }
              />
            </Box>
            <Stack direction="row" mt={6} spacing={1}>
              <Button
                variant="contained"
                color="black"
                size="small"
                sx={{
                  flex: 1,
                }}
                onClick={() => handleSetAmount(0.25)}
              >
                25%
              </Button>
              <Button
                variant="contained"
                color="black"
                size="small"
                sx={{
                  flex: 1,
                }}
                onClick={() => handleSetAmount(0.5)}
              >
                50%
              </Button>
              <Button
                variant="contained"
                color="black"
                size="small"
                sx={{
                  flex: 1,
                }}
                onClick={() => handleSetAmount(1)}
              >
                MAX
              </Button>
            </Stack>
            <Stack mt={11} direction="row" spacing={1}>
              <Button
                variant="outlined"
                color="raisinBlack"
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="raisinBlack"
                onClick={handleRedeem}
              >
                Redeem
              </Button>
            </Stack>
          </Box>
        </Modal>
        <Modal open={guide} onClose={handleToggleGuide}>
          <Box>
            <Typography fontSize={32} lineHeight={1.25} color="raisinBlack">
              Why do we lock 200 XP on each level?
            </Typography>
            <Typography
              fontSize={16}
              lineHeight={1.75}
              color="raisinBlack"
              mt={3}
              component="div"
            >
              From Level 3 onward, we reserve 200 XP to cover Oily Ambassador
              NFT minting costs and ensure you always have enough XP to keep
              progressing. This supports the program&apos;s sustainability and
              ongoing enhancements, helping everyone reach higher levels without
              roadblocks.
              <br />
              <br />
              <b>Heads up:</b> extra XP prerequisites apply for higher Oily
              levels. Check them <NavLink to="/about/journey">here</NavLink>.
            </Typography>
            <Box mt={11}>
              <Button
                variant="outlined"
                color="raisinBlack"
                onClick={handleToggleGuide}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Modal>
      </Scaffold.Content>
    </Scaffold>
  );
};
