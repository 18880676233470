/* eslint-disable no-undef */

export const snackbar = {
  maxSnack: process.env.REACT_APP_ENV === 'production' ? 1 : 10,
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  preventDuplicate: true,
  autoHideDuration: 2000,
};
