import React, { useCallback } from 'react';
import { Scaffold } from '../../components/layouts/Scaffold';
import { ButtonBase, Stack } from '@mui/material';
import { XpIndicator } from '../../components/core/XpIndicator';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ProgressSection } from './ProgressSection';
import { RecommendedActionSection } from './RecommendedActionSection';
import { NftSection } from './NftSection';
import { TokenLaunchSection } from './TokenLaunchSection';
import { RestaurantsSection } from './RestaurantsSection';
import { WmosSection } from './WmosSection';
import { BalanceSection } from './BalanceSection';
import { ArticleSection } from './ArticlesSection';
import { LevelUpSection } from './LevelUpSection';
import { RafflesSection } from './RafflesSection';
import { AboutJourneySection } from './AboutJourneySection';

export const HomePage = () => {
  const navigate = useNavigate();

  const { avatar } = useSelector((state) => state.profile);

  const handleEditProfile = useCallback(() => {
    navigate('/profile');
  }, [navigate]);

  return (
    <Scaffold>
      <Scaffold.Header>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          pt={1}
          pb={1}
          minHeight={56}
        >
          <ButtonBase
            sx={{
              bgcolor: 'raisinBlack',
              width: 24,
              height: 24,
              borderRadius: '100%',
              overflow: 'hidden',
            }}
            onClick={handleEditProfile}
          >
            <img
              src={avatar}
              alt="profile"
              style={{
                width: '100%',
                height: '100%',
              }}
            />
          </ButtonBase>
          <XpIndicator />
        </Stack>
      </Scaffold.Header>
      <Scaffold.Content>
        <ProgressSection />
        <LevelUpSection />
        <RecommendedActionSection />
        <NftSection />
        <TokenLaunchSection />
        <RestaurantsSection />
        <WmosSection />
        <BalanceSection />
        <AboutJourneySection />
        <RafflesSection />
        <ArticleSection />
      </Scaffold.Content>
    </Scaffold>
  );
};
