/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  ButtonBase,
  Menu,
  MenuItem,
  Modal,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { LockIcon } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth as useNFIDAuth } from '@nfid/identitykit/react';
import { removeAuth } from '../../redux/auth.slice';
import { removeProfile } from '../../redux/profile.slice';
import { apiDisconnectWallet } from '../../apis/wallet';

import imgBg from '../../assets/backgrounds/main-bg.png';
import { ReactComponent as SvgLogo } from '../../assets/brands/dark-brand-logo-sm.svg';
import { ReactComponent as HomeIcon } from '../../assets/custom-icons/home.svg';
import { ReactComponent as ProgressIcon } from '../../assets/custom-icons/progress.svg';
import { ReactComponent as RedeemIcon } from '../../assets/custom-icons/redeem.svg';
import { ReactComponent as MenuIcon } from '../../assets/custom-icons/menu.svg';

const TabBarItem = styled(ButtonBase)(({ theme, selected, muted }) => ({
  flex: 1,
  position: 'relative',
  paddingTop: 10,
  paddingBottom: 6,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: selected
    ? theme.palette.white
    : muted
      ? theme.palette.shadowBlue
      : theme.palette.beauBlue,
  borderRadius: 16,
  transition: 'all 0.2s',

  '& .MuiTypography-root': {
    fontSize: 10,
    lineHeight: 1,
    marginTop: 4,
  },
  '& nav': {
    display: selected ? 'block' : 'none',
  },
}));

const HighlightBar = styled('nav')(({ theme }) => ({
  background: theme.palette.icterine,
  width: 16,
  height: 3,
  borderRadius: '2px 2px 0 0',
  position: 'absolute',
  bottom: 0,
  left: '50%',
  transition: 'all 0.2s ease',
  transform: 'translateX(-50%)',
}));

const MoreMenu = styled(Menu)(({ theme }) => ({
  background: '#00000080',
  '& .MuiPaper-root': {
    background: theme.palette.icterine,
    borderRadius: 24,
    boxShadow: 'none',
    width: 'calc(100% - 16px)',
    transform: 'translate(-50%, -100px) !important',
    paddingTop: 32,
    paddingBottom: 24,
    maxWidth: 624,
    boxSizing: 'border-box',
    left: '50% !important',

    '& .MuiList-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& .MuiMenuItem-root': {
      padding: '8px 40px',
      fontSize: 18,
      fontWeight: 500,
      lineHeight: '26px',
    },
  },
}));

export const AppBottomNavigationBar = ({ sx }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const nfidAuth = useNFIDAuth();

  const { level } = useSelector((state) => state.profile);
  const { chainName } = useSelector((state) => state.wallet);

  const [isMore, setIsMore] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [modals, setModals] = useState({
    redeem: false,
  });

  const isHome = useMemo(() => {
    if (location.pathname.includes('/home') && !isMore) {
      return true;
    }
    return false;
  }, [location, isMore]);

  const isProgress = useMemo(() => {
    if (location.pathname.includes('/progress') && !isMore) {
      return true;
    }
    return false;
  }, [location, isMore]);

  const isChallenges = useMemo(() => {
    if (
      (location.pathname.includes('/challenges') ||
        location.pathname.includes('/l2e') ||
        location.pathname.includes('/m2e')) &&
      !isMore
    ) {
      return true;
    }
    return false;
  }, [location, isMore]);

  const isRedeem = useMemo(() => {
    if (location.pathname.includes('/redeem') && !isMore) {
      return true;
    }
    return false;
  }, [location, isMore]);

  const handleNavigate = useCallback(
    (pathname) => {
      return () => {
        navigate(pathname);
        setIsMore(false);
        setAnchorEl(null);
      };
    },
    [navigate],
  );

  const handleOpenMenu = useCallback((ev) => {
    setIsMore(true);
    setAnchorEl(ev.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setIsMore(false);
    setAnchorEl(null);
  }, []);

  const handleLogout = useCallback(async () => {
    setIsMore(false);
    setAnchorEl(null);

    dispatch(removeAuth());
    dispatch(removeProfile());
    if (chainName === 'nfid') {
      await nfidAuth.disconnect();
    }
    apiDisconnectWallet();
    navigate('/welcome');
  }, [dispatch, navigate, chainName]);

  const handleToggleModal = useCallback((name) => {
    return () => {
      setModals((value) => ({
        ...value,
        [name]: !value[name],
      }));
    };
  }, []);

  return (
    <Stack
      direction="row"
      sx={{
        backgroundColor: 'rgba(12, 12, 13, 0.80)',
        backdropFilter: 'blur(4px)',
        position: 'fixed',
        bottom: 16,
        right: 16,
        left: '50%',
        maxWidth: 608,
        width: 'calc(100% - 32px)',
        transform: 'translateX(-50%)',
        px: 0.5,
        borderRadius: 4,
        ...sx,
      }}
    >
      <TabBarItem selected={isHome} onClick={handleNavigate('/home')}>
        <HomeIcon />
        <Typography>Home</Typography>
        <HighlightBar />
      </TabBarItem>
      <TabBarItem selected={isProgress} onClick={handleNavigate('/progress')}>
        <ProgressIcon />
        <Typography>Progress</Typography>
        <HighlightBar />
      </TabBarItem>
      <TabBarItem
        component="div"
        sx={{
          px: 1,
        }}
        disableRipple
        disableTouchRipple
        selected={isChallenges}
      >
        <Box width={24} height={24} />
        <Typography>Challenges</Typography>
        <ButtonBase
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            width: 60,
            height: 60,
            borderRadius: 100,
            bgcolor: 'icterine',
            position: 'absolute',
            top: -30,
            left: 'calc(50% - 30px)',
            boxShadow: '0px 0px 12px 0px rgba(235, 241, 85, 0.70)',
          }}
          onClick={handleNavigate('/challenges')}
        >
          <SvgLogo
            style={{
              width: 32,
              height: 32,
            }}
          />
        </ButtonBase>
        <HighlightBar />
      </TabBarItem>
      <TabBarItem
        selected={isRedeem}
        muted={level < 3}
        onClick={
          level < 3 ? handleToggleModal('redeem') : handleNavigate('/redeem')
        }
      >
        <RedeemIcon />
        <Typography>Redeem</Typography>
        <HighlightBar />
        {level < 3 ? (
          <Stack
            width={18}
            height={18}
            alignItems="center"
            justifyContent="center"
            borderRadius="100%"
            color="isabelline"
            bgcolor="yellow.700"
            position="absolute"
            top={9}
            left="50%"
          >
            <LockIcon width={10} height={10} />
          </Stack>
        ) : null}
      </TabBarItem>
      <TabBarItem selected={isMore} onClick={handleOpenMenu}>
        <MenuIcon />
        <Typography>More</Typography>
        <HighlightBar />
      </TabBarItem>
      <MoreMenu
        open={isMore}
        onClose={handleCloseMenu}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleNavigate('/progress')}>
          View My Progress
        </MenuItem>
        <MenuItem onClick={handleNavigate('/leaderboard')}>
          Top 10 Oily Contributors
        </MenuItem>
        <MenuItem disabled onClick={handleNavigate('/xxx')}>
          Ambassador Lottery (Coming Soon!)
        </MenuItem>
        <MenuItem onClick={handleNavigate('/about/m2e')}>
          Requesting Mapping Info
        </MenuItem>
        <MenuItem onClick={handleNavigate('/about/uco-network')}>
          About UCO Network
        </MenuItem>
        <MenuItem onClick={handleNavigate('/about/oily-dapp')}>
          About Oily dApp
        </MenuItem>
        <MenuItem
          disabled={level < 7}
          onClick={handleNavigate('/about/token-launch')}
        >
          Oily Token Launch
        </MenuItem>
        <MenuItem onClick={handleNavigate('/about/journey')}>
          Learn about your journey
        </MenuItem>
        <MenuItem onClick={handleNavigate('/profile')}>Settings</MenuItem>
        <MenuItem onClick={handleLogout}>Log out</MenuItem>
      </MoreMenu>
      <Modal open={modals.redeem} onClose={handleToggleModal('redeem')}>
        <Box>
          <Typography fontSize={32} lineHeight={1.25} color="raisinBlack">
            Feature Locked!
          </Typography>
          <Typography
            fontSize={16}
            lineHeight={1.75}
            color="raisinBlack"
            mt={3}
          >
            You need to move to Level 3 to unlock this feature. Complete the
            required Learn & Earn challenges and 3 days of logins to level up
            and access Redeem functionality!
          </Typography>
          <Box mt={11}>
            <Button
              variant="outlined"
              color="raisinBlack"
              onClick={handleToggleModal('redeem')}
            >
              Ok, I understand
            </Button>
          </Box>
        </Box>
      </Modal>
    </Stack>
  );
};

export const AppLayout = () => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        background: `url(${imgBg})`,
        backgroundPosition: '0 50px',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Outlet />
      <AppBottomNavigationBar />
    </Box>
  );
};

AppBottomNavigationBar.propTypes = {
  sx: PropTypes.any,
};
