import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import { GlobalLoadingModal } from '../modals/GlobalLoadingModal';
import { LevelUp1Dialog } from '../dialogs/LevelUp1Dialog';
import { LevelUp2Dialog } from '../dialogs/LevelUp2Dialog';
import { LevelUp3Dialog } from '../dialogs/LevelUp3Dialog';
import { LevelUp4Dialog } from '../dialogs/LevelUp4Dialog';
import { LevelUp5Dialog } from '../dialogs/LevelUp5Dialog';
import { LevelUp6Dialog } from '../dialogs/LevelUp6Dialog';
import { LevelUp7Dialog } from '../dialogs/LevelUp7Dialog';
import { LevelUp8Dialog } from '../dialogs/LevelUp8Dialog';
import { LevelUp9Dialog } from '../dialogs/LevelUp9Dialog';
import { LevelUp10Dialog } from '../dialogs/LevelUp10Dialog';
import { MintNftDialog } from '../dialogs/MintNftDialog';
import { CompleteNftDialog } from '../dialogs/CompleteNftDialog';
import {
  PrepareLevelUp1Dialog,
  PrepareLevelUp2Dialog,
  PrepareLevelUp3Dialog,
  PrepareLevelUp4Dialog,
  PrepareLevelUp5Dialog,
  PrepareLevelUp6Dialog,
  PrepareLevelUp7Dialog,
  PrepareLevelUp8Dialog,
  PrepareLevelUp9Dialog,
  PrepareLevelUp10Dialog,
} from '../dialogs/PrepareLevelUpDialogs';
import { useDispatch, useSelector } from 'react-redux';
import {
  toggleFeatureDialog,
  toggleLevelUpDialog,
} from '../../redux/app.slice';

import imgBrandBg from '../../assets/brands/brand-bg.png';
import imgBrandLogo from '../../assets/brands/brand-logo.png';

export const AppProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { isReady, isGlobalLoading, dialogs } = useSelector(
    (state) => state.app,
  );

  const handleToggleLevelUpDialog = useCallback(
    (level) => {
      return () => {
        dispatch(
          toggleLevelUpDialog({
            level: level,
          }),
        );
      };
    },
    [dispatch],
  );

  const handleToggleFeatureDialog = useCallback(
    (name) => {
      return () => {
        dispatch(
          toggleFeatureDialog({
            name: name,
          }),
        );
      };
    },
    [dispatch],
  );

  if (isReady) {
    return (
      <React.Fragment>
        {children}
        <GlobalLoadingModal open={isGlobalLoading} />
        <LevelUp1Dialog
          open={dialogs.levelUp1}
          onClose={handleToggleLevelUpDialog(1)}
        />
        <LevelUp2Dialog
          open={dialogs.levelUp2}
          onClose={handleToggleLevelUpDialog(2)}
        />
        <LevelUp3Dialog
          open={dialogs.levelUp3}
          onClose={handleToggleLevelUpDialog(3)}
        />
        <LevelUp4Dialog
          open={dialogs.levelUp4}
          onClose={handleToggleLevelUpDialog(4)}
        />
        <LevelUp5Dialog
          open={dialogs.levelUp5}
          onClose={handleToggleLevelUpDialog(5)}
        />
        <LevelUp6Dialog
          open={dialogs.levelUp6}
          onClose={handleToggleLevelUpDialog(6)}
        />
        <LevelUp7Dialog
          open={dialogs.levelUp7}
          onClose={handleToggleLevelUpDialog(7)}
        />
        <LevelUp8Dialog
          open={dialogs.levelUp8}
          onClose={handleToggleLevelUpDialog(8)}
        />
        <LevelUp9Dialog
          open={dialogs.levelUp9}
          onClose={handleToggleLevelUpDialog(9)}
        />
        <LevelUp10Dialog
          open={dialogs.levelUp10}
          onClose={handleToggleLevelUpDialog(10)}
        />
        <MintNftDialog
          open={dialogs.mintNft}
          onClose={handleToggleFeatureDialog('mintNft')}
        />
        <CompleteNftDialog
          open={dialogs.completeNft}
          onClose={handleToggleFeatureDialog('completeNft')}
        />
        <PrepareLevelUp1Dialog
          open={dialogs.prepareLevelUp1}
          onClose={handleToggleFeatureDialog('prepareLevelUp1')}
        />
        <PrepareLevelUp2Dialog
          open={dialogs.prepareLevelUp2}
          onClose={handleToggleFeatureDialog('prepareLevelUp2')}
        />
        <PrepareLevelUp3Dialog
          open={dialogs.prepareLevelUp3}
          onClose={handleToggleFeatureDialog('prepareLevelUp3')}
        />
        <PrepareLevelUp4Dialog
          open={dialogs.prepareLevelUp4}
          onClose={handleToggleFeatureDialog('prepareLevelUp4')}
        />
        <PrepareLevelUp5Dialog
          open={dialogs.prepareLevelUp5}
          onClose={handleToggleFeatureDialog('prepareLevelUp5')}
        />
        <PrepareLevelUp6Dialog
          open={dialogs.prepareLevelUp6}
          onClose={handleToggleFeatureDialog('prepareLevelUp6')}
        />
        <PrepareLevelUp7Dialog
          open={dialogs.prepareLevelUp7}
          onClose={handleToggleFeatureDialog('prepareLevelUp7')}
        />
        <PrepareLevelUp8Dialog
          open={dialogs.prepareLevelUp8}
          onClose={handleToggleFeatureDialog('prepareLevelUp8')}
        />
        <PrepareLevelUp9Dialog
          open={dialogs.prepareLevelUp9}
          onClose={handleToggleFeatureDialog('prepareLevelUp9')}
        />
        <PrepareLevelUp10Dialog
          open={dialogs.prepareLevelUp10}
          onClose={handleToggleFeatureDialog('prepareLevelUp10')}
        />
      </React.Fragment>
    );
  }

  return (
    <Stack
      width="100%"
      height="100vh"
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        backgroundImage: `url(${imgBrandBg})`,
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
      }}
    >
      <img src={imgBrandLogo} alt="Logo" width={105} height={105} />
    </Stack>
  );
};

AppProvider.propTypes = {
  children: PropTypes.element,
};
