import React from 'react';
import { Box, ButtonBase, Stack, Typography, useTheme } from '@mui/material';
import { ArrowRightIcon } from 'lucide-react';
import { NavLink } from 'react-router-dom';

import imgOily from '../../assets/onboarding/oily-level-3-narrow.png';

export const AboutJourneySection = () => {
  const theme = useTheme();

  return (
    <ButtonBase
      sx={{
        display: 'block',
        textAlign: 'left',
      }}
      component={NavLink}
      to="/about/journey"
    >
      <Box bgcolor="purpleNavy" p={4}>
        <Typography
          fontSize={32}
          fontWeight={500}
          lineHeight={1.25}
          color="isabelline"
        >
          Your Journey as an Oily Ambassador
        </Typography>
        <Stack mt={2} direction="row">
          <img src={imgOily} alt="Oily" />
          <Typography
            ml={2.5}
            flex={1}
            fontSize={16}
            lineHeight={1.75}
            color="isabelline"
          >
            See how your actions create real change! Explore levels, discover
            rewards, and become the ambassador for a cleaner planet!
          </Typography>
        </Stack>
        <Stack mt={3} direction="row" alignItems="center" spacing={2}>
          <Typography fontSize={32} lineHeight={1.25} color="greyishBlue">
            Explore your path
          </Typography>
          <ArrowRightIcon color={theme.palette.greyishBlue} />
        </Stack>
      </Box>
    </ButtonBase>
  );
};
