import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Confetti from 'react-confetti';
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import { Scaffold } from '../layouts/Scaffold';
import { InfoIcon, XIcon } from 'lucide-react';

import imgOily from '../../assets/onboarding/oily-level-4.png';
import imgBadge from '../../assets/badges/badge-lvl4.png';
import imgBadgeNext from '../../assets/badges/badge-lvl5.png';

export const LevelUp4Dialog = ({ open, onClose }) => {
  const [guide, setGuide] = useState(false);

  const handleContinue = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <Scaffold bgcolor="levels.4.bgcolor" height="100vh">
        <Confetti recycle={false} numberOfPieces={1000} />
        <Scaffold.Header>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            px={2}
            pt={1}
            pb={2}
            minHeight={56}
          >
            <Box width={26} />
            <Typography fontSize={14} color="isabelline" lineHeight={1}>
              Level Completed
            </Typography>
            <IconButton color="isabelline" size="small" onClick={onClose}>
              <XIcon />
            </IconButton>
          </Stack>
        </Scaffold.Header>
        <Scaffold.Content>
          <Stack direction="row" justifyContent="center">
            <img src={imgOily} alt="Oily" />
          </Stack>
          <Box px={3}>
            <Typography
              mt={3}
              fontSize={32}
              lineHeight={1.25}
              color="isabelline"
            >
              Congratulations!
              <br />
              You&apos;ve Reached
            </Typography>
            <Typography fontSize={32} lineHeight={1.25} color="beauBlue">
              Level 4: UCO Enthusiast
            </Typography>
            <Stack direction="row" alignItems="flex-start" spacing={2} mt={2}>
              <img
                src={imgBadge}
                alt="Badge"
                style={{
                  width: 72,
                  height: 72,
                }}
              />
              <Typography
                flex={1}
                fontSize={20}
                lineHeight={1.4}
                color="isabelline"
              >
                Incredible work! You&apos;re making a real impact on
                sustainability.
              </Typography>
            </Stack>

            <Typography
              mt={4}
              fontSize={20}
              fontWeight={500}
              lineHeight={1.4}
              color="beauBlue"
            >
              Your Achievements:
            </Typography>
            <Typography
              mt={1}
              fontSize={18}
              fontWeight={700}
              lineHeight={1.75}
              color="isabelline"
            >
              Completed
            </Typography>
            <Typography
              fontSize={14}
              lineHeight={1.75}
              color="isabelline"
              component="div"
            >
              <ul>
                <li>All 16 Learn and Earn Chapters</li>
              </ul>
            </Typography>
            <Typography
              mt={1}
              fontSize={18}
              fontWeight={700}
              lineHeight={1.75}
              color="isabelline"
            >
              Achieved
            </Typography>
            <Typography
              fontSize={14}
              lineHeight={1.75}
              color="isabelline"
              component="div"
            >
              <ul>
                <li>14 days of activity on the platform</li>
              </ul>
            </Typography>
            <Typography
              mt={1}
              fontSize={18}
              fontWeight={700}
              lineHeight={1.75}
              color="isabelline"
            >
              Unlocked
            </Typography>
            <Typography
              fontSize={14}
              lineHeight={1.75}
              color="isabelline"
              component="div"
            >
              <ul>
                <li>
                  The ability to apply for the Map and Earn feature—start
                  mapping and earning now!
                </li>
              </ul>
            </Typography>
            <Typography
              mt={4}
              fontSize={20}
              fontWeight={500}
              lineHeight={1.4}
              color="beauBlue"
            >
              What&apos;s Next on Your Path?
            </Typography>
            <Stack mt={1} direction="row" alignItems="flex-start" spacing={2}>
              <img
                src={imgBadgeNext}
                alt="Oily"
                style={{
                  width: 32,
                  height: 32,
                }}
              />
              <Typography fontSize={16} lineHeight={1.75} color="isabelline">
                <b>Level 5: Map Explorer</b>
                <br />
                Keep the momentum! Accumulate 5000+ XP, spend 30+ days on the
                platform, and lock 3000 XP to unlock limited mapping
                capabilities and take your journey further.
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mt={6}
              pb={2}
            >
              <Typography fontSize={16} lineHeight={1} color="beauBlue">
                Why will we lock 3000 XPs?
              </Typography>
              <IconButton
                color="isabelline"
                size="small"
                onClick={() => setGuide(true)}
              >
                <InfoIcon />
              </IconButton>
            </Stack>
          </Box>
          <Box mt={5} px={4} pb={9}>
            <Button
              variant="contained"
              color="icterine"
              fullWidth
              onClick={handleContinue}
            >
              Continue
            </Button>
          </Box>
          <Modal open={guide} onClose={() => setGuide(false)}>
            <Box>
              <Typography fontSize={32} lineHeight={1.25} color="raisinBlack">
                What about the 3000 XPs being blocked at level 5?
              </Typography>
              <Typography
                fontSize={16}
                lineHeight={1.75}
                color="raisinBlack"
                mt={3}
                component="div"
              >
                At Level 5, an additional 3,000 XP is temporarily locked as a
                commitment to unlock simple mapping features. As soon as you
                reach Level 6 - after successfully mapping 10 restaurants-, this
                block is automatically lifted, returning the XP to your balance.
                This ensures you can explore high-level mapping tools,
                understanding the commitment of mapping real world data—and as
                an active mapper, you&apos;ll also enjoy better conversion rates
                and exclusive benefits.
              </Typography>
              <Box mt={11}>
                <Button
                  variant="outlined"
                  color="raisinBlack"
                  onClick={() => setGuide(false)}
                >
                  Close
                </Button>
              </Box>
            </Box>
          </Modal>
        </Scaffold.Content>
      </Scaffold>
    </Dialog>
  );
};

LevelUp4Dialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
