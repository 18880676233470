/* eslint-disable no-undef */

import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { store } from '../redux/store';
import { updateProfile } from '../redux/profile.slice';
import { apiRunFingerprintjs } from './fingerprintjs';

export const apiClaimReward = ({ amount }) => {
  return new Promise((resolve) => {
    grecaptcha.ready(async () => {
      try {
        const token = await grecaptcha.execute(
          process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY,
          { action: 'redeem' },
        );
        const response = await axios.post('/api/client/v1/user/claim-reward', {
          recaptcha_token: token,
          amount: amount,
        });
        store.dispatch(
          updateProfile({
            xp: (store.getState().profile.xp ?? 0) - amount,
            reward: (store.getState().profile.reward ?? 0) - amount,
          }),
        );

        await apiRunFingerprintjs();
        resolve(response.data.data.price);
      } catch (e) {
        if (e.response?.data?.error === 'NOTHING_FOR_REWARD') {
          enqueueSnackbar({
            message: 'You have no XP to claim',
            variant: 'error',
          });
        } else if (e.response?.data?.error === 'TOO_MANY_REQUESTS') {
          enqueueSnackbar({
            message:
              'You are trying to claim your reward too many times. Please try again later',
            variant: 'error',
          });
        } else if (e.response?.data?.error === 'SCAMMER_USER') {
          enqueueSnackbar({
            message:
              'Your account has been flagged for suspicious activity and blocked from redeeming',
            variant: 'error',
          });
        } else if (e.response?.data?.error === 'WAITING_FOR_ADMIN_SET_LIMIT') {
          enqueueSnackbar({
            message: "We're replenishing the rewards pool. Stand by.",
            variant: 'error',
          });
        } else if (e.response?.data?.error === 'EXCEED_WEEKLY_LIMIT') {
          enqueueSnackbar({
            message: "We're replenishing the rewards pool. Stand by.",
            variant: 'error',
          });
        } else {
          enqueueSnackbar({
            message: 'An error occurred. Please try again later',
            variant: 'error',
          });
        }
        resolve(0);
      }
    });
  });
};

export const apiGetTransactions = async ({ skip, limit }) => {
  try {
    const response = await axios.post('/api/client/v1/user/transactions', {
      skip,
      limit,
    });
    return response.data.data;
  } catch {
    return [];
  }
};

export const apiGetXpHistory = async ({ skip, limit }) => {
  try {
    const response = await axios.post('/api/client/v1/user/xp-history', {
      skip,
      limit,
    });
    const data = response.data.data;

    if (data?.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].status === 'received') {
          data[i].status = 'Received';
        } else {
          data[i].status = 'Paid';
          data[i].xp = -data[i].xp;
        }

        switch (data[i].type) {
          case 'total': {
            data[i].type = 'Your Total XPs';
            break;
          }
          case 'm2e_approve_place': {
            data[i].type = 'Approved Restaurant';
            break;
          }
          case 'm2e_validate_mapping': {
            data[i].type = 'Validated Restaurant';
            break;
          }
          case 'm2e_validate_wmo': {
            data[i].type = 'Validated WMO';
            break;
          }
          case 'l2e_answer_question': {
            data[i].type = 'Answered Question';
            break;
          }
          case 'redeem': {
            data[i].type = 'Redeem';
            break;
          }
          case 'mint_nft': {
            data[i].type = 'Mint Ambassador NFT';
            break;
          }
          default: {
            break;
          }
        }
      }
    }
    return data;
  } catch {
    return [];
  }
};

export const apiMintNft = async () => {
  try {
    await axios.post('/api/client/v1/user/mint-nft');
    return true;
  } catch {
    return false;
  }
};

export const apiGetMinimumXDCRate = async () => {
  try {
    const response = await axios.get(
      'https://bridge.vet/api/swap/b3tr/xdc/range',
    );
    return response.data.min;
  } catch {
    return null;
  }
};

export const apiGetMinimumICPRate = async () => {
  try {
    const response = await axios.get(
      'https://bridge.vet/api/swap/b3tr/icp/range',
    );
    return response.data.min;
  } catch {
    return null;
  }
};
